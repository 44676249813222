const TIMEOUT = 8000;
const ACTIVE_CLASS = 'show';

const renderNotification = (title, text, cssClass) => {
  let timer = null;

  const wrapper = document.createElement('div');
  wrapper.setAttribute('data-cy', 'notification-item');
  wrapper.className = `notification-item ${cssClass}`;

  const titleEl = document.createElement('div');
  titleEl.className = 'notification-item__title';
  titleEl.innerHTML = title;
  titleEl.setAttribute('data-cy', 'notification-item-title');
  wrapper.appendChild(titleEl);

  const textEl = document.createElement('div');
  textEl.className = 'notification-item__message';
  textEl.innerHTML = text;
  textEl.setAttribute('data-cy', 'notification-item-message');
  wrapper.appendChild(textEl);

  const remove = () => {
    clearTimeout(timer);
    wrapper.classList.remove(ACTIVE_CLASS);

    setTimeout(() => {
      requestAnimationFrame(() => {
        wrapper.remove();
      });
    }, 500);
  };

  setTimeout(remove, TIMEOUT);
  wrapper.addEventListener('click', remove);

  return  wrapper;
};

class NotificationService {
  constructor() {
      this._notificationWrapper = null;
  }

  get wrapper() {
    if (!this._notificationWrapper) {
      this._notificationWrapper = document.createElement('div');
      this._notificationWrapper.className = `notification-list`;

      requestAnimationFrame(() => {
        document.body.appendChild(this._notificationWrapper);
      });
    }

    return this._notificationWrapper;
  }

  show ({ title= '', body= '', type = 'success'} = {}) {
    const modifierCss = (type == 'error' || type == 'danger') ? 'notification-item--error' : '';
    const notification = renderNotification(title, body, modifierCss);

      requestAnimationFrame(() => {
        this.wrapper.appendChild(notification);
        notification.classList.add('show');
      });
  }
}

export const notificationService = new NotificationService();

window.__moni.services.notification = notificationService;
