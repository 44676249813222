import {notificationService} from '../components/notification-service';

document.addEventListener('DOMContentLoaded', () => {
  for (const {title, content, type} of window.__moni.app.flashes) {
    notificationService.show({
      title,
      type,
      body: content,
    })
  }
});